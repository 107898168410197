<template>
  <div id="qrCode">
    <div id='code'></div>
    <canvas id="canvas"></canvas>
  </div>
</template>
<style></style>
<script>
  import QRCode from 'qrcode'
  export default {
    data() {
      return {
        msg: 'hello vue',
        codes: '',
        url: ''

      }
    },
    components: {
      QRCode: QRCode
    },
    methods: {
      useqrcode() {
        var canvas = document.getElementById('canvas')
        QRCode.toCanvas(canvas, this.url, function(error) {
          if (error) console.error(error)
          console.log('QRCode success!')
        })
      },
      open(params) {
        this.url = params
        console.log(this.url);
        this.useqrcode()
      }
    },
    mounted() {
      // this.useqrcode()
    }
  }
</script>
